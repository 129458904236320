import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";

import ButtonShop from "./buttonShop";
import LanguageSwitcher from "./languageSwitcher";

import { Nav } from "../utils/textStyles";
import { sizes, colors, easings, getSiteMetaData } from "../utils";
import { translationFuncShape } from "../utils/shapes";

const StyledNavigation = styled.ul`
  flex-direction: row;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  .nav-item {
    margin-right: 0.8rem;

    @media (min-width: ${sizes.desktop}) {
      margin-right: 1rem;
    }

    @media (min-width: ${sizes.xxldesktop}) {
      margin-right: 1.6rem;
    }
  }

  .nav-item:last-child {
    margin: 0;
  }

  .nav-link {
    ${Nav}
    text-decoration: none;
    color: black;
    text-align: center;
    transition: color 0.3s ${easings.default};
  }

  .nav-link:hover {
    color: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.gray300};
  }
`;

const Navigation = ({ className, t, langCode, locales }) => {
  const { theme } = getSiteMetaData();
  const shopUrl = theme === "TH" ? t("link-shop-th") : t("link-shop-ck");

  return (
    <StyledNavigation className={className}>
      <li className="nav-item">
        <Link to={`/${langCode}`} className="nav-link">
          {t("nav-home")}
        </Link>
      </li>
      <li className="nav-item">
        <Link to={`/${langCode}/faq`} className="nav-link">
          {t("nav-faq")}
        </Link>
      </li>
      {locales.length > 1 && (
        <li>
          <LanguageSwitcher currentLang={langCode} locales={locales} />
        </li>
      )}
      <li>
        <ButtonShop href={shopUrl} size="small">
          {t("nav-shop-button")}
        </ButtonShop>
      </li>
    </StyledNavigation>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  t: translationFuncShape.isRequired,
  langCode: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired
};

Navigation.defaultProps = {
  className: "",
  langCode: "en"
};

export default Navigation;
