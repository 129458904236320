import React from "react";
import PropTypes from "prop-types";

const GlobalContext = React.createContext();

class GlobalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        title: "",
        type: "",
        locale: ""
      },
      localNavToggled: false,
      updateState: newValues => {
        this.setState(state => ({ ...state, ...newValues }));
      }
    };
  }

  render() {
    const { children } = this.props;
    return (
      <GlobalContext.Provider value={{ state: this.state }}>
        {children}
      </GlobalContext.Provider>
    );
  }
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { GlobalContext };
export default GlobalProvider;
