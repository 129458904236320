/* global window */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { Button } from "../atoms";
import Chevron from "../atoms/icons/chevron";

import { colors, easings } from "../utils";
import { CTAs } from "../utils/textStyles";
import { GameContext } from "../context/gameContext";
import { GlobalContext } from "../context/globalContext";

export const StyledShopButton = styled(Button)`
  ${CTAs}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  ${({ type }) =>
    type === "default" &&
    css`
      color: ${colors.white};
      background: ${({ theme }) =>
        theme.brand === "TH" ? colors.green : colors.black};
      border: 1px solid transparent;
    `}

  ${({ type }) =>
    type === "hollow" &&
    css`
      color: ${colors.black};
      background: transarent;
      border: 1px solid ${colors.black};
    `}

  .chevron {
    margin-top: -0.1rem;
    margin-left: ${props => (props.size === "large" ? `1rem` : `0.7rem`)};
    transition: transform 0.3s ${easings.default};
  }

  .chevron path {
    stroke: ${props =>
      props.type === "default" ? colors.white : colors.black};
  }

  &:hover {
    .chevron {
      transform: translateX(50%);
    }
  }
`;

const ButtonShop = ({ children, className, href, size, type }) => {
  const { gameState } = useContext(GameContext);
  const {
    state: { page }
  } = useContext(GlobalContext);

  const newHref = `${href}?lessons_finished=${gameState.length}`;

  const sendAnalyticsEvent = () => {
    if (window.utag) {
      window.utag.link({
        tealium_event: "shop_button_click",
        lessons_finished: gameState,
        button_href: href,
        ...page
      });
    }
  };

  return (
    <StyledShopButton
      href={newHref}
      className={className}
      as="a"
      size={size}
      type={type}
      onClick={sendAnalyticsEvent}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      <Chevron className="chevron" />
    </StyledShopButton>
  );
};

ButtonShop.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  type: PropTypes.oneOf(["default", "hollow"])
};

ButtonShop.defaultProps = {
  className: "",
  href: "",
  size: "large",
  type: "default"
};

export default ButtonShop;
