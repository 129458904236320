// Styling settings
const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blue: "#1B2E46",
  offwhite: "#EDEFF2",
  green: "#50AC74",
  greenCK: "#17995A",
  gray50: "#EFEFEF",
  gray100: "#F6F6F6",
  gray200: "#D8D8D8",
  gray300: "#E4E4E4",
  gray500: "#787878",
  gray1000: "#404040",
  accent: "#425163",
  highlight: "#50AC74"
};

const sizes = {
  xxsmobile: "320px",
  xsmobile: "375px",
  smobile: "560px",
  tablet: "720px",
  desktop: "1024px",
  xldesktop: "1200px",
  xxldesktop: "1400px",
  xxxldesktop: "1600px",
  huge: "1900px",
  xlhuge: "2560px"
};

const easings = {
  default: "cubic-bezier(0.51, 0.01, 0.2, 0.99)",
  bounce: "cubic-bezier(0.58, 0.05, 0.14, 1.59)",
  smooth: "cubic-bezier(0.73, 0.01, 0.21, 0.96)"
};

module.exports = {
  colors,
  sizes,
  easings
};
