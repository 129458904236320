import styled, { css } from "styled-components";
import { sizes } from "../utils";

// Large padding sizes with media queries
const large = css`
  padding: 1rem 1.8rem;

  @media (min-width: ${sizes.desktop}) {
    padding: 1.5rem 2.2rem;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 1.5rem 3rem;
  }
`;

// Picker
const paddingPicker = size => {
  switch (size) {
    case "large":
      return large;
    case "medium":
      return "padding: 1rem 1.8rem";
    case "small":
    default:
      return "padding: 0.7rem 1.3rem";
  }
};

// Styled component
const Button = styled.button`
  position: relative;
  ${({ size }) => paddingPicker(size)};
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  background: transparent;
`;

export default Button;
