import React from "react";

const LogoCK = () => (
  <svg
    viewBox="0 0 640.7 99.4"
    width="125"
    height="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M280.5,18.7h-10.4V6.9h10.4V18.7z M280.3,97.7h-10.1V30.4h10.1V97.7z" />
      <path
        d="M302.7,97.7h-10.1V30.4h10.1v11.3c1.3-3.7,7.2-12.8,21.1-12.8c18.8,0,27.6,13.8,27.6,30.8v38h-10.2V60
		c0-12.7-6.8-21.5-19.1-21.5c-12.1,0-19.4,9.1-19.4,22V97.7z"
      />
      <path d="M410.1,53.7L398,67.4v30.3h-11.1V1.8H398v51.2l45-51.2h13.3l-38.5,43.4l38.5,52.6h-12.8L410.1,53.7z" />
      <path d="M472.2,97.7h-10.1V0h10.1V97.7z" />
      <path
        d="M516,99.2c-19.7,0-34.3-15.2-34.3-35.2c0-19.8,14.7-35.2,34-35.2c19.7,0,34.4,15.7,33.9,37.7H492
		c0.8,12.9,10.6,23.1,24,23.1s19.9-8.1,22.6-13.7h10.1C546.2,85.3,536.2,99.2,516,99.2z M492.6,57.7h46.2c-2-10.1-9.6-19.4-23-19.4
		C502.7,38.2,494.3,48.1,492.6,57.7z"
      />
      <path d="M569.7,18.7h-10.4V6.9h10.4V18.7z M569.6,97.7h-10.1V30.4h10.1V97.7z" />
      <path
        d="M592,97.7h-10.1V30.4H592v11.3c1.3-3.7,7.2-12.8,21.1-12.8c18.8,0,27.6,13.8,27.6,30.8v38h-10.2V60
		c0-12.7-6.8-21.5-19.1-21.5c-12.1,0-19.4,9.1-19.4,22V97.7z"
      />
      <path
        d="M11.5,49.9C11.5,72.3,27.6,89,48.7,89c19.7,0,29.4-12.1,32.5-18.2h11.1c-2.8,9.2-16.1,28.5-43.6,28.5
		C21,99.4,0,78.4,0,49.7C0,21.5,21.2,0.1,48.7,0.1c27.5,0,40.8,18.9,43.9,28.8H81.1c-3.5-6.3-13-18.4-32.4-18.4
		C27.6,10.5,11.5,27.2,11.5,49.9z"
      />
      <path
        d="M132.4,99.2c-19.2,0-34.3-15.1-34.3-35.2c0-20.1,15.1-35.2,34.3-35.2c15.2,0,22.4,9.7,24.4,14.2V30.4h10.1v67.3h-10.1V85
		C154.6,89.6,147.6,99.2,132.4,99.2z M156.9,64.1c0-14.5-10.2-25.6-24.2-25.6c-14.1,0-24.3,11-24.3,25.6c0,14.5,10.2,25.6,24.3,25.6
		C146.7,89.6,156.9,78.5,156.9,64.1z"
      />
      <path d="M189.3,97.7h-10.1V0h10.1V97.7z" />
      <polygon points="229.4,87.3 250.8,30.4 261.9,30.4 235.8,97.7 223,97.7 196.9,30.4 208.1,30.4 	" />
    </g>
  </svg>
);

export default LogoCK;
