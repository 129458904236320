import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
import { bodyText } from "../utils/textStyles";

const StyledWysiwyg = styled(Markdown)`
  ${bodyText}
  line-height: 1.5;

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    list-style-position: inside;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
  }

  ul li,
  ol li {
    margin-bottom: 0.5rem;
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;
  }
  ol li {
    counter-increment: ol-counter;
  }
  ol li::before {
    content: counter(ol-counter) ". ";
    font-weight: 400;
  }
`;

const Wysiwyg = ({ className, content }) => (
  <StyledWysiwyg className={className}>{content}</StyledWysiwyg>
);

Wysiwyg.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string
};

Wysiwyg.defaultProps = {
  className: ""
};

export default Wysiwyg;
