/* global window, document */
import { graphql, useStaticQuery } from "gatsby";

import { colors, sizes, easings } from "../settings";

/* This function formats the index to a presentable number for layout purposes */
const formatIndexNumber = index => (index < 10 ? `0${index + 1}` : index + 1);

/* This function formats a string of text so it can be used as a slug or id */
const makeAnchor = string => string.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();

/* This function returns site meta data fetched with useStaticQuery */
const getSiteMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            theme
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

const pushTeliumView = data => {
  if (window.utag) {
    window.utag.view(data);
  } else {
    document.addEventListener("utag-loaded", () => {
      window.utag.view(data);
    });
  }
};

export {
  colors,
  sizes,
  formatIndexNumber,
  makeAnchor,
  easings,
  getSiteMetaData,
  pushTeliumView
};
