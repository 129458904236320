import React from "react";
import PropTypes from "prop-types";

const Chevron = ({ className }) => (
  <svg
    className={className}
    width="9"
    height="12"
    viewBox="0 0 9 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l6 4.714L1 11"
      stroke="#FFF"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

Chevron.propTypes = {
  className: PropTypes.string
};

Chevron.defaultProps = {
  className: ""
};

export default Chevron;
