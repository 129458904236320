const getTranslationFn = translationIndex => key => {
  const result = translationIndex[key];
  if (result === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`Translation not found for key ${key}`);
    return "- missing translation -";
  }
  return result;
};

export default getTranslationFn;
