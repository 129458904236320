// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-404-jsx": () => import("./../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-home-jsx": () => import("./../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-lesson-jsx": () => import("./../src/templates/lesson.jsx" /* webpackChunkName: "component---src-templates-lesson-jsx" */)
}

