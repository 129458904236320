import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Logo from "../components/logo";
import HeaderTitle from "../components/headerTitle";
import Navigation from "../components/navigation";
import ButtonClose from "../components/buttonClose";

import { colors, sizes, easings, getSiteMetaData } from "../utils";
import { translationFuncShape } from "../utils/shapes";

const StyledHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 5.3215rem;
  background: ${props =>
    props.page !== "lesson" ? colors.white : "transparent"};
  z-index: 200;
  transition: background 0.7s ease-out;

  @media (min-width: ${sizes.xxldesktop}) {
    height: 5.625rem;
  }

  .title {
    display: none;

    @media (min-width: ${sizes.tablet}) {
      display: block;
      position: absolute;
      top: 50%;
      left: 4vw;
      transform: translateY(-50%);
      opacity: ${props => (props.page !== "lesson" ? 1 : 0)};
      pointer-events: ${props =>
        props.page !== "lesson" ? "default" : "none"};
      transition: opacity 0.4s ease-out;
    }
  }

  .logo {
    display: none;

    @media (min-width: ${sizes.desktop}) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: ${props => (props.page !== "lesson" ? 1 : 0)};
      pointer-events: ${props =>
        props.page !== "lesson" ? "default" : "none"};
      transition: opacity 0.4s ease-out;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .navigation {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 1rem;
    opacity: ${props => (props.page !== "lesson" ? 1 : 0)};
    pointer-events: ${props => (props.page !== "lesson" ? "default" : "none")};
    transition: opacity 0.4s ease-out;

    @media (min-width: ${sizes.desktop}) {
      width: unset;
      right: 4vw;
    }
  }

  .close {
    position: absolute;
    top: 50%;
    right: 4.5vw;
    opacity: ${props => (props.page !== "lesson" ? 0 : 1)};
    transform: ${props =>
      props.page !== "lesson" ? "translate(0, -100%)" : "translate(0, -50%)"};
    transition: opacity 0.5s ${easings.default},
      transform 0.3s ${easings.default};
  }
`;

const Header = ({ t, pageType, langCode, locales }) => {
  const { theme } = getSiteMetaData();

  return (
    <StyledHeader page={pageType}>
      <HeaderTitle
        className="title"
        theme={theme}
        title={t("header-title")}
        langCode={langCode}
      />
      <Logo className="logo" theme={theme} langCode={langCode} />
      <Navigation
        className="navigation"
        t={t}
        langCode={langCode}
        locales={locales}
      />
      <ButtonClose className="close">{t("button-close")}</ButtonClose>
    </StyledHeader>
  );
};

Header.propTypes = {
  t: translationFuncShape.isRequired,
  pageType: PropTypes.string.isRequired,
  langCode: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Header;
