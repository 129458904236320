import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";

import Close from "../atoms/icons/close";

import { Nav } from "../utils/textStyles";

const StyledButtonClose = styled(Link)`
  ${Nav}
  display: flex;
  flex-direction: row;
  cursor: pointer;

  span {
    display: inline-block;
    margin-left: 0.5rem;
  }

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
`;

/* TODO: Link to the correct language when they exist */
const ButtonClose = ({ children, className }) => {
  return (
    <StyledButtonClose to="/" className={className}>
      {children}
      <span>
        <Close />
      </span>
    </StyledButtonClose>
  );
};

ButtonClose.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ButtonClose.defaultProps = {
  className: ""
};

export default ButtonClose;
