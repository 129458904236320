import PropTypes from "prop-types";

export const assetShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  media: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    _links: PropTypes.shape({
      site: PropTypes.shape({
        href: PropTypes.string.isRequired
      })
    })
  })
});

export const blockShape = PropTypes.shape({
  type: PropTypes.oneOf([
    "b2bonboardingbrxm:textandmedia",
    "b2bonboardingbrxm:largemedia"
  ]).isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  tommyMedia: assetShape.isRequired,
  ckMedia: assetShape.isRequired
});

export const lessonShape = PropTypes.shape({
  title: PropTypes.string,
  slug: PropTypes.string,
  introduction: PropTypes.string,
  duration: PropTypes.number,
  introImage: assetShape,
  lessonblocks: PropTypes.arrayOf(blockShape),
  lessonfinishedtext: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  thcustomshoplink: PropTypes.string,
  ckcustomshoplink: PropTypes.string
});

export const topicShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  lessons: PropTypes.arrayOf(lessonShape),
  tommyImage: assetShape,
  ckImage: assetShape,
  progressIcon: assetShape,
  progressIconDone: assetShape
});

export const localeShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  default: PropTypes.bool.isRequired
});

export const errorPageFieldsShape = PropTypes.shape({
  title: PropTypes.string,
  heading: PropTypes.string,
  copy: PropTypes.string,
  homeButtonLabel: PropTypes.string,
  tommyMedia: assetShape,
  ckMedia: assetShape
});

export const faqPageFieldsShape = PropTypes.shape({
  title: PropTypes.string,
  heading: PropTypes.string,
  slug: PropTypes.string,
  copy: PropTypes.string,
  faqButtonLabelTommy: PropTypes.string,
  faqButtonUrlTommy: PropTypes.string,
  faqButtonLabelCK: PropTypes.string,
  faqButtonUrlCK: PropTypes.string
});

export const translationIndexShape = PropTypes.objectOf(PropTypes.string);

export const translationFuncShape = PropTypes.func;
