import React from "react";
import styled from "styled-components";

import { sizes, colors, getSiteMetaData } from "../utils";
import { smallTitle, footnoteText, smallCopy } from "../utils/textStyles";
import { translationFuncShape } from "../utils/shapes";

const Container = styled.div`
  ${smallCopy}
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem 2rem;

  @media (min-width: ${sizes.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2.3rem 4% 2.6rem;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 3rem 3% 3rem 2%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    span {
      display: block;
      margin: 10px;
    }
  }

  div:first-child {
    order: 2;

    @media (min-width: ${sizes.tablet}) {
      order: 1;
    }
  }

  div:last-child {
    order: 1;

    @media (min-width: ${sizes.tablet}) {
      order: 2;
    }
  }

  .right {
    @media (min-width: ${sizes.tablet}) {
      text-align: right;
    }
  }

  .right a {
    color: ${colors.black};
    text-decoration: none;
  }

  .right a:hover {
    text-decoration: underline;
  }

  .title {
    ${smallTitle}
  }

  .footnote {
    font-weight: 300;
    @media (min-width: ${sizes.xxldesktop}) {
      font-size: 0.8rem;
    }
    ${footnoteText}
    color: ${colors.gray500};
  }
`;

const Footer = ({ t }) => {
  const { theme } = getSiteMetaData();

  const cs =
    theme === "TH"
      ? { label: t("footer-cs-label-th"), url: t("footer-cs-url-th") }
      : { label: t("footer-cs-label-ck"), url: t("footer-cs-url-ck") };

  const credit = {
    label: t("footer-credit-label"),
    url: t("footer-credit-url")
  };

  const colophon =
    theme === "TH" ? t("footer-colophon-th") : t("footer-colophon-ck");

  return (
    <Container>
      <div>
        <span className="footnote">{colophon}</span>
      </div>
      <div className="right">
        <span className="title">{t("footer-contact")}</span>
        <span>
          <a href={cs.url} target="_blank" rel="noopener noreferrer">
            {cs.label}
          </a>
        </span>
        <span>
          <a href={credit.url} target="_blank" rel="noopener noreferrer">
            {credit.label}
          </a>
        </span>
      </div>
    </Container>
  );
};

Footer.propTypes = {
  t: translationFuncShape.isRequired
};

export default Footer;
