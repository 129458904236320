import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../utils";
import { metaTitleText } from "../utils/textStyles";

const StyledMetaTitle = styled.div`
  ${metaTitleText}
  
  color: ${props => colors[props.titleColor]};
  .number {
    margin-right: 0.2rem;
  }
`;

/* Meta titles are smaller titles that indicate a section, topic etc */
const MetaTitle = ({ className, children, sectionNumber, color }) => (
  <StyledMetaTitle className={className} titleColor={color}>
    {sectionNumber && <span className="number">{sectionNumber}</span>}
    {children}
  </StyledMetaTitle>
);

MetaTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  sectionNumber: PropTypes.string,
  color: PropTypes.oneOf(["blue", "green", "black", "white", "accent"])
};

MetaTitle.defaultProps = {
  className: "",
  sectionNumber: null,
  color: "black"
};

export default MetaTitle;
