import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

import { headerTitleText } from "../utils/textStyles";
import { colors } from "../utils";

const StyledHeaderTitle = styled(Link)`
  ${headerTitleText}
  color: ${colors.black};
  text-decoration: none;

  span {
    display: inline-block;
  }

  span:first-child {
    margin-right: 0.25rem;
  }

  span:last-child {
    font-weight: 700;
  }
`;

const HeaderTitle = ({ className, theme, title, langCode }) => {
  const brand = theme === "TH" ? "Tommy Hilfiger" : "Calvin Klein";
  return (
    <StyledHeaderTitle to={`/${langCode}`} className={className}>
      <span>{brand}</span>
      <span>{title}</span>
    </StyledHeaderTitle>
  );
};

HeaderTitle.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  langCode: PropTypes.string
};

HeaderTitle.defaultProps = {
  className: "",
  theme: "TH",
  title: "B2B Onboarding",
  langCode: "en"
};

export default HeaderTitle;
