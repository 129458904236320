/* global localStorage */
import React, { Component } from "react";
import PropTypes from "prop-types";

const GameContext = React.createContext();

class GameContextProvider extends Component {
  constructor(props) {
    super(props);
    this.onTaskDone = this.onTaskDone.bind(this);
    this.isDone = this.isDone.bind(this);

    // Default state
    this.state = {
      gameState: []
    };
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      gameState:
        JSON.parse(localStorage.getItem("game_state")) === null
          ? []
          : JSON.parse(localStorage.getItem("game_state"))
    }));
  }

  onTaskDone(id) {
    const { gameState } = this.state;
    if (gameState.indexOf(id) === -1) {
      const newState = gameState.concat([id]);
      localStorage.setItem("game_state", JSON.stringify(newState));
      this.setState(() => ({ gameState: newState }));
    }
  }

  isDone(id) {
    const { gameState } = this.state;
    return gameState.indexOf(id) !== -1;
  }

  render() {
    const { gameState } = this.state;
    const { children } = this.props;

    return (
      <GameContext.Provider
        value={{
          gameState,
          onTaskDone: this.onTaskDone,
          isDone: this.isDone
        }}
      >
        {children}
      </GameContext.Provider>
    );
  }
}

GameContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { GameContext };
export default GameContextProvider;
