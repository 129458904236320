import { css } from "styled-components";

import { sizes, colors } from "./index";

const extraLight = 200;
const light = 300;
const medium = 500;
const bold = 700;
const extraBold = 800;

const poppins = "Poppins, Arial, Helvetica, sans-serif";

export const heroTitle = css`
  font-family: ${poppins};
  font-size: 2.81rem;
  font-weight: ${extraLight};
  line-height: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 3.25rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 3.625rem;
  }
`;

export const heroSubtitle = css`
  font-family: ${poppins};
  font-size: 1.5rem;
  font-weight: ${bold};
  line-height: 1.375;
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.68rem;
    line-height: 1.3;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 2.18rem;
    line-height: 1.14;
  }
`;

export const heroDescription = css`
  font-family: ${poppins};
  font-weight: ${light};
  font-size: 1rem;
  line-height: 1.8;

  @media (min-width: ${sizes.desktop}) {
    font-size: 1.06rem;
    line-height: 1.7;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
`;

export const heroLinks = css`
  font-family: ${poppins};
  font-weight: ${bold};
  font-size: 0.8125rem;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 0.9rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1rem;
  }
`;

export const CTAs = css`
  font-family: ${poppins};
  font-weight: ${extraBold};
  font-size: 0.8rem;
  letter-spacing: 0.05rem;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 0.77rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1rem;
  }
`;

export const Nav = css`
  font-family: ${poppins};
  font-weight: ${bold};
  font-size: 0.8125rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.black};
`;

/* This is the general body copy */
export const bodyText = css`
  font-family: ${poppins};
  font-size: 0.93rem;
  font-weight: ${light};
  line-height: 1.8;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.06rem;
    line-height: 1.76;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.25rem;
    line-height: 1.7;
  }
`;

export const topicDescription = css`
  font-family: ${poppins};
  font-size: 1rem;
  font-weight: ${light};
  line-height: 1.68;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.06rem;
    line-height: 1.76;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.25rem;
    line-height: 1.7;
  }
`;

export const headerTitleText = css`
  font-family: ${poppins};
  font-size: 0.8125rem;
  font-weight: ${medium};
  letter-spacing: 0.02rem;
  text-transform: uppercase;
`;

export const metaTitleText = css`
  font-family: ${poppins};
  font-size: 0.875rem;
  font-weight: ${medium};
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 1;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1rem;
  }
`;

export const lessonTitleText = css`
  font-family: ${poppins};
  font-size: 1.875rem;
  font-weight: ${extraBold};
  text-transform: uppercase;
  line-height: 1.3;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 2.68rem;
  }
`;

export const lessonStepTitle = css`
  font-family: ${poppins};
  font-size: 1.56rem;
  font-weight: ${extraBold};
  line-height: 1.6;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.75rem;
    line-height: 1.5;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 2.1875rem;
    line-height: 1.4;
  }
`;

export const lessonTopicText = css`
  font-family: ${poppins};
  font-size: 0.875rem;
  font-weight: ${bold};
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.125rem;
  }
`;

export const lessonMetaMinor = css`
  font-family: ${poppins};
  font-size: 8.125rem;
  font-weight: ${medium};
`;

export const lessonFinishedTitle = css`
  font-family: ${poppins};
  font-size: 2.1875rem;
  font-weight: ${extraLight};
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 2.8125rem;
  }
`;

export const lessonNextTitle = css`
  font-family: ${poppins};
  font-size: 1.56rem;
  font-weight: ${extraBold};
  letter-spacing: 0.09rem;
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 2rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 2.187rem;
  }
`;

export const smallTitle = css`
  font-family: ${poppins};
  font-size: 0.875rem;
  font-weight: ${extraBold};
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1rem;
  }
`;

export const smallCopy = css`
  font-family: ${poppins};
  font-size: 0.815rem;
  font-weight: ${medium};

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 0.93rem;
  }
`;

export const footnoteText = css`
  font-family: ${poppins};
  font-size: 0.75rem;
  font-weight: ${medium};

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 0.875rem;
  }
`;

export const minutes = css`
  font-family: ${poppins};
  font-size: 0.71rem;
  font-weight: ${light};
  text-transform: uppercase;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 0.8125rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 0.875rem;
  }
`;

export const cardTitle = css`
  font-family: ${poppins};
  font-size: 1rem;
  font-weight: ${extraBold};
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 0.05rem;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.06rem;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.3rem;
  }
`;

export const topicNumberText = css`
  font-family: ${poppins};
  font-size: 1.56rem;
  font-weight: ${light};
  text-transform: uppercase;
  letter-spacing: 0.06rem;

  @media (min-width: ${sizes.huge}) {
    font-size: 2.06rem;
  }
`;

export const topicTitleText = css`
  font-family: ${poppins};
  font-weight: ${extraBold};
  font-size: 2.8125rem;
  letter-spacing: 0.05rem;
  line-height: 1.1;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 3rem;
  }
  @media (min-width: ${sizes.huge}) {
    font-size: 3.75rem;
  }
`;

export const topicDescriptionText = css`
  font-family: ${poppins};
  font-weight: ${light};
  font-size: 0.93rem;
  letter-spacing: 0;
  line-height: 1.6;

  @media (min-width: ${sizes.xxldesktop}) {
    font-size: 1.06rem;
    line-height: 1.7;
  }

  @media (min-width: ${sizes.huge}) {
    font-size: 1.25rem;
  }
`;
