import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

import LogoTommy from "../atoms/icons/logoTommy";
import LogoCK from "../atoms/icons/logoCK";

/* TODO: Hook up languages & set correct href to link */
const Logo = ({ className, theme, langCode }) => {
  return (
    <StyledLogo className={className} to={`/${langCode}`} brand={theme}>
      {theme === "TH" ? <LogoTommy /> : <LogoCK />}
    </StyledLogo>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  langCode: PropTypes.string
};

Logo.defaultProps = {
  className: "",
  theme: "TH",
  langCode: "en"
};

const StyledLogo = styled(Link)`
  cursor: pointer;

  ${({ brand }) =>
    brand === "TH" &&
    css`
      width: 2.5rem;
      height: 1.625rem;
    `}

  ${({ brand }) =>
    brand === "CK" &&
    css`
      width: 7.8125rem;
      height: 1.06rem;
    `}

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export default Logo;
