import React from "react";
import PropTypes from "prop-types";

const LogoTommy = ({ className }) => (
  <svg
    className={className}
    width="39"
    height="25"
    viewBox="0 0 39 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#00174F" fillRule="nonzero" d="M0 0h38.095v25H0z" />
      <path fill="#C8102E" d="M19.048 19.048h19.047V5.952H19.048z" />
      <path fill="#FFF" d="M0 19.048h19.048V5.952H0z" />
    </g>
  </svg>
);

LogoTommy.propTypes = {
  className: PropTypes.string
};

LogoTommy.defaultProps = {
  className: ""
};

export default LogoTommy;
