import styled from "styled-components";
import { sizes } from "../utils";

const Section = styled.div`
  padding: 4rem 1rem 4rem;

  @media (min-width: ${sizes.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    padding: 8rem 4% 5rem 14%;
    min-height: 37rem;
  }

  @media (min-width: ${sizes.xldesktop}) {
    padding: 12rem 10% 6rem 25%;
  }
`;

export default Section;
