import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { assetShape } from "../utils/shapes";

const StyledImage = styled.img`
  border-radius: ${props => (props.borderRadius ? "4px" : "0")};
  box-shadow: ${props =>
    props.shadow ? "0 10px 10px rgba(0, 0, 0, 0.03)" : "none"};
`;

const Image = ({ className, image, borderRadius, shadow, useSrcSet }) => {
  const isBynderImage = image.media.type === "b2beubrxm:Bynder";
  const imageType = !isBynderImage && image.media.original.mimeType;

  const jpegCompress =
    imageType === "image/jpeg" ? "?fm=jpg&fl=progressive" : "";

  const compressedSrc = isBynderImage
    ? image.media.bynderLink.thumbnails.webimage
    : // eslint-disable-next-line no-underscore-dangle
      `${image.media._links.site.href}${jpegCompress}`;

  // Create srcset only for jpgs and components which have useSrcSet true
  if (imageType === "image/jpeg" && useSrcSet === true) {
    const srcSet = `
      ${compressedSrc}&w=600 600w,
      ${compressedSrc}&w=800 800w,
      ${compressedSrc}&w=1300 1000w,
      ${compressedSrc}&w=1500 1300w,
      ${compressedSrc} 1500w,
    `;

    return (
      <StyledImage
        className={className}
        src={`${compressedSrc}w=400`}
        alt={image.displayName}
        borderRadius={borderRadius}
        shadow={shadow}
        srcSet={srcSet}
      />
    );
  }

  // Default image will compress jpegs, but nothing more
  return (
    <StyledImage
      className={className}
      src={compressedSrc}
      alt={image.displayName}
      borderRadius={borderRadius}
      shadow={shadow}
    />
  );
};

Image.propTypes = {
  image: assetShape.isRequired,
  borderRadius: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  useSrcSet: PropTypes.bool
};

Image.defaultProps = {
  borderRadius: false,
  shadow: false,
  className: "",
  useSrcSet: false
};

export default Image;
