/* global window */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Chevron from "../atoms/icons/chevron";

import { Nav } from "../utils/textStyles";
import { colors, sizes } from "../utils";

const StyledLangSwitcher = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  margin-right: 1.2rem;
  padding-left: 0.8rem;

  @media (min-width: ${sizes.desktop}) {
    margin-right: 1.6rem;
  }

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    background: ${colors.gray200};
    position: absolute;
    left: 0;
    top: 0;
  }

  .lang-toggle {
    ${Nav}
    display: relative;
    padding: 0.2rem 0.7rem 0.2rem 0.5rem;
    border: none;
    background: transparent;
    color: ${colors.gray500};
    transition: color 0.3s ease-out;
    cursor: pointer;
  }

  .lang-toggle:hover {
    color: ${colors.black};
  }

  .lang-toggle:focus {
    color: ${colors.black};
    outline: none;
  }

  .lang-toggle-chevron {
    display: block;
    position: absolute;
    right: 0;
    top: 40%;
    width: 6px;
    height: 8px;
    transform: rotate(90deg);

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    svg path {
      stroke: ${colors.gray500};
      transition: color 0.3s ease-out;
    }
  }

  .lang-toggle:hover .lang-toggle-chevron svg path {
    stroke: ${colors.black};
  }

  .lang-options {
    display: ${props => (props.menuOpen ? "block" : "none")};
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lang-options a {
    ${Nav}
    color: ${colors.gray500};
    padding: 0.2rem 0.7rem 0.2rem 0.5rem;
  }
`;

const LanguageSwitcher = ({ currentLang, locales }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const availableLangs = locales.filter(lang => lang !== currentLang);

  const handleToggleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLangClick = (e, lang) => {
    setMenuOpen(false);

    if (window.utag) {
      window.utag.link({
        tealium_event: "language_switch_button_click",
        lang_switch_locale: lang
      });
    }
  };

  const closeToggle = e => {
    if (!e.target.classList.contains("js-lang-switcher")) {
      setMenuOpen(false);
      removeCloseListener(); // eslint-disable-line
    }
  };

  const addCloseListener = () => {
    window.addEventListener("click", closeToggle);
  };

  const removeCloseListener = () => {
    window.removeEventListener("click", closeToggle);
  };

  useEffect(() => {
    if (menuOpen) {
      addCloseListener();
    } else {
      removeCloseListener();
    }
  }, [menuOpen]);

  return (
    <StyledLangSwitcher menuOpen={menuOpen}>
      <li>
        <button
          className="lang-toggle"
          type="button"
          onClick={handleToggleClick}
        >
          {currentLang.toUpperCase()}
          <span className="lang-toggle-chevron">
            <Chevron />
          </span>
        </button>
        <ul className="lang-options">
          {availableLangs.map(lang => (
            <li key={lang}>
              <a href={`/${lang}`} onClick={e => handleLangClick(e, lang)}>
                {lang.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </li>
    </StyledLangSwitcher>
  );
};

LanguageSwitcher.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLang: PropTypes.string.isRequired
};

export default LanguageSwitcher;
