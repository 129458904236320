/* global document */
exports.onInitialClientRender = () => {
  const urlParts = document.URL.split("#");
  const anchor = urlParts.length > 1 ? urlParts[1] : null;

  if (anchor) {
    const elem = document.getElementById(anchor);
    elem.scrollIntoView();
  }
};
