import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";

import Footer from "./footer";
import Header from "./header";
import GlobalStyle from "../utils/cssReset";
import GameContextProvider from "../context/gameContext";
import GlobalProvider from "../context/globalContext";

import getTranslationFn from "../utils/translations";
import { getSiteMetaData } from "../utils";
import { translationIndexShape, localeShape } from "../utils/shapes";

const Layout = props => {
  const {
    children,
    pageContext: { page, translationIndex, pageType, locale, localesArray }
  } = props;

  // When the dummy pages under /page is rendered just return an empty footer
  if (!translationIndex) {
    return null;
  }

  const { title } = page;

  const { theme } = getSiteMetaData();
  const brand = theme === "TH" ? "Tommy Hilfiger" : "Calvin Klein";

  const t = getTranslationFn(translationIndex);
  const langCode = locale.code.toLowerCase().substring(0, 2);

  // Setup styled components theme for site
  const siteTheme = {
    brand: theme
  };

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:200,300,500,700,800&display=swap"
          rel="stylesheet"
        />
        <title>
          {title} - {brand} {t("header-title")}
        </title>
        <link rel="shortcut icon" href={`${theme.toLowerCase()}/favicon.ico`} />
        <html lang={langCode} />
      </Helmet>
      <GlobalProvider>
        <GameContextProvider>
          <ThemeProvider theme={siteTheme}>
            <GlobalStyle />
            <Header
              t={t}
              pageType={pageType}
              langCode={langCode}
              locales={localesArray}
            />
            {children}
            <Footer t={t} />
          </ThemeProvider>
        </GameContextProvider>
      </GlobalProvider>
    </>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.shape({
    translationIndex: translationIndexShape,
    pageType: PropTypes.string,
    locale: localeShape,
    localesArray: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.shape({
      title: PropTypes.string
    })
  }),
  children: PropTypes.node.isRequired
};

// We allow dummy pages with no values.
Layout.defaultProps = {
  pageContext: {
    translationIndex: undefined,
    pageType: undefined,
    locale: undefined
  }
};

export default Layout;
