import React from "react";
import PropTypes from "prop-types";

const Close = ({ className }) => (
  <svg
    className={className}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="#000"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.492 1.992l11.016 11.016M12.804 2.197L2.197 12.803" />
    </g>
  </svg>
);

Close.propTypes = {
  className: PropTypes.string
};

Close.defaultProps = {
  className: ""
};

export default Close;
